html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;

    /* 텍스트 스크롤 방지 */
    -moz-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

button {
    border: none;
    background-color: unset;
    padding: unset;
}

/* reset divider */

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body * {
    -webkit-tap-highlight-color: transparent;
}

img {
    -webkit-touch-callout: none;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.swiper {
    width: 100%;
    height: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    /*background-color: red;*/
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 200px;
    height: 200px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 200px;
    height: 200px;
    /*background-color: blue;*/
}

.swiper-slide-prev {
    opacity: 0.6;
}

.swiper-slide-next {
    opacity: 0.6;
}

.swiper .swiper-pagination-bullet {
    background-color: #1C1E22;
    width: 6px;
    height: 6px;
    margin: 0 10px !important;
}

@font-face {
    font-family: 'SUIT';
    font-weight: 100;
    src: url('fonts/SUIT-Thin.woff2') format('woff2');
}
@font-face {
    font-family: 'SUIT';
    font-weight: 200;
    src: url('fonts/SUIT-ExtraLight.woff2') format('woff2');
}
@font-face {
    font-family: 'SUIT';
    font-weight: 300;
    src: url('fonts/SUIT-Light.woff2') format('woff2');
}
@font-face {
    font-family: 'SUIT';
    font-weight: 400;
    src: url('fonts/SUIT-Regular.woff2') format('woff2');
}
@font-face {
    font-family: 'SUIT';
    font-weight: 500;
    src: url('fonts/SUIT-Medium.woff2') format('woff2');
}
@font-face {
    font-family: 'SUIT';
    font-weight: 600;
    src: url('fonts/SUIT-SemiBold.woff2') format('woff2');
}
@font-face {
    font-family: 'SUIT';
    font-weight: 700;
    src: url('fonts/SUIT-Bold.woff2') format('woff2');
}
@font-face {
    font-family: 'SUIT';
    font-weight: 800;
    src: url('fonts/SUIT-ExtraBold.woff2') format('woff2');
}
@font-face {
    font-family: 'SUIT';
    font-weight: 900;
    src: url('fonts/SUIT-Heavy.woff2') format('woff2');
}